var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pt-7"},[_c('div',{staticClass:"pb-5"},[_c('div',{staticClass:"my-bav__table mb-5"},[_c('v-card',[_c('v-card-text',[_c('h2',[_vm._v(_vm._s(_vm.$t('myBavSearchNFilter')))])]),_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"px-0",attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{staticClass:"mb-5 me-3",attrs:{"single-line":"","dense":"","outlined":"","hide-details":"","clearable":"","label":_vm.$t('formPlaceholderSearch'),"placeholder":_vm.$t('formPlaceholderSearch')},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1)],1)],1),_c('v-data-table',{staticClass:"text-no-wrap pointer",attrs:{"headers":_vm.headers,"items":_vm.loadedUsers,"server-items-length":_vm.totalUsers,"loading":_vm.loadingUsers,"footer-props":{
            'items-per-page-options': [15, 30, 50, 100],
            'items-per-page-text': _vm.$t('itemsPerPage'),
          }},on:{"update:items-per-page":_vm.setLimit,"click:row":_vm.handleRowClick,"pagination":_vm.handlePagination},scopedSlots:_vm._u([{key:"header.trending",fn:function(){return [_c('v-icon',{attrs:{"size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiTrendingUp)+" ")])]},proxy:true},{key:"item.email",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap th"},[_vm._v(_vm._s(item.email))])]}},{key:"item.id",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap th"},[_vm._v(_vm._s(item.id))])]}}],null,true)},[_c('template',{slot:"no-data"},[_c('div',{staticClass:"my-5"},[_c('p',[_vm._v(_vm._s(_vm.$t('myBavNoResults')))])])])],2)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }