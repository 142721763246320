<template>
  <div class="py-sm-10 py5">
    <v-row justify="center">
      <v-col cols="12" sm="12" md="10" lg="10">
        <v-card v-if="org" class="v-card--no-border v-card--no-bg">
          <h1 class="font-weight-bold mb-5 text-center">
            {{ org.organizationName }}
          </h1>
          <p class="mb-11 text-center">ID: {{ org.id }}</p>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="center" class="mb-10">
      <v-col cols="12" sm="12" md="10" lg="10">
        <v-tabs v-model="currentTab" grow>
          <v-tab>Users</v-tab>
          <v-tab>Companies</v-tab>
        </v-tabs>

        <v-tabs-items v-model="currentTab">
          <v-tab-item>
            <global-admin-org-users />
          </v-tab-item>
          <v-tab-item>
            <global-admin-org-companies />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref, getCurrentInstance } from '@vue/composition-api';
import { getOrganizationById } from '../../api/organizations';
import GlobalAdminOrgCompanies from './organization/GlobalAdminOrgCompanies';
import GlobalAdminOrgUsers from './organization/GlobalAdminOrgUsers';

export default {
  name: 'GlobalAdminOrgPage',
  components: { GlobalAdminOrgCompanies, GlobalAdminOrgUsers },
  setup() {
    const vm = getCurrentInstance().proxy;

    const currentTab = ref(0);
    const org = ref(null);

    const fetchOrg = () => {
      getOrganizationById(vm.$route.params.id).then((res) => {
        org.value = res.data;
      });
    };

    fetchOrg();

    return {
      currentTab,
      org,
    };
  },
};
</script>

<style scoped></style>
