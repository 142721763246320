<template>
  <div class="pt-7">
    <div class="pb-5">
      <div class="my-bav__table mb-5">
        <v-card>
          <v-card-text>
            <h2>{{ $t('myBavSearchNFilter') }}</h2>
          </v-card-text>
          <v-card-text class="d-flex align-center flex-wrap pb-0">
            <v-row class="px-3">
              <v-col cols="12" md="4" class="px-0">
                <v-text-field
                  v-model="searchQuery"
                  single-line
                  dense
                  outlined
                  hide-details
                  clearable
                  :label="$t('formPlaceholderSearch')"
                  :placeholder="$t('formPlaceholderSearch')"
                  class="mb-5 me-3"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

          <v-data-table
            :headers="headers"
            :items="loadedUsers"
            :server-items-length="totalUsers"
            :loading="loadingUsers"
            :footer-props="{
              'items-per-page-options': [15, 30, 50, 100],
              'items-per-page-text': $t('itemsPerPage'),
            }"
            class="text-no-wrap pointer"
            @update:items-per-page="setLimit"
            @click:row="handleRowClick"
            @pagination="handlePagination"
          >
            <template #[`header.trending`]>
              <v-icon size="22">
                {{ icons.mdiTrendingUp }}
              </v-icon>
            </template>

            <template #[`item.email`]="{ item }">
              <span class="text-no-wrap th">{{ item.email }}</span>
            </template>

            <template #[`item.id`]="{ item }">
              <span class="text-no-wrap th">{{ item.id }}</span>
            </template>

            <template slot="no-data">
              <div class="my-5">
                <p>{{ $t('myBavNoResults') }}</p>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, getCurrentInstance, watch } from '@vue/composition-api';

export default {
  name: 'GlobalAdminOrgUsers',
  setup() {
    const vm = getCurrentInstance().proxy;

    const loadedUsers = ref([]);
    const totalUsers = ref(0);
    const loadingUsers = ref(false);

    const limit = ref(15);
    const offset = ref(0);
    const currentPage = ref(1);
    const searchQuery = ref('');

    const headers = computed(() => {
      return [
        {
          text: vm.$t('Email'),
          value: 'email',
          sortable: false,
          class: 'text-uppercase',
        },
        {
          text: vm.$t('ID'),
          value: 'id',
          sortable: false,
          class: 'text-uppercase',
        },
      ];
    });

    const handlePagination = (event) => {
      if (currentPage.value < event.page) offset.value += limit.value;
      if (currentPage.value > event.page) offset.value -= limit.value;

      currentPage.value = event.page;
    };

    const setLimit = (v) => {
      limit.value = v;
      currentPage.value = 1;
      offset.value = 0;
    };

    const handleRowClick = (user) => {
      vm.$router.push(`/profile/${user.id}`);
    };

    return {
      loadedUsers,
      totalUsers,
      loadingUsers,
      headers,
      searchQuery,
      setLimit,
      handlePagination,
      handleRowClick,
    };
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
